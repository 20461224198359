<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="110px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="姓名：" prop="fullName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.fullName }}</p>
                <el-input clearable v-else v-model="ruleForm.fullName" placeholder="请输入姓名" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="账号：" prop="userName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.userName }}</p>
                <el-input clearable v-else v-model="ruleForm.userName" placeholder="请输入账号" maxlength=18 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="密码：" prop="password">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.password }}</p>
                <el-input clearable v-else v-model="ruleForm.password" placeholder="请输入密码" maxlength=18 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="手机：" prop="mobilePhoneNumber">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.mobilePhoneNumber }}</p>
                <el-input clearable v-else v-model="ruleForm.mobilePhoneNumber" placeholder="请输入手机" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
let _t = null
export default {
  name: "accountEdit",
  components: {
  },
  props:{
  },
  data() {
    return {   
      rules: {
        fullName: [{ required: true,  trigger: "change", message: '请输入姓名'}],
        password: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入密码"))
            }else{
              if(val.length < 6){
                callback(new Error("密码长度要大于等于6位"))
              }else{
                callback()
              }
            }
          }
        }],
        userName: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入账号"))
            }else{
              if(val.length < 6){
                callback(new Error("账号长度要大于等于6位"))
              }else{
                callback()
              }
            }
          }
        }],
        mobilePhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {
        fullName: '',
        userName: '',
        password: '',
        mobilePhoneNumber: '',
      },
      InitForm: {
      }
    };
  },
  created() {
	},
  mounted(){
    _t = this
  },
  methods: {
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = {...this.InitForm}
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'saveaccount' : 'saveaccount',
            target = {
            ..._this.ruleForm,
            companyId: utils.getBodyId(),
            bodyType: utils.getBodyType()
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message('新增成功！', "success")
              _this.$emit("getData", editType == 'add' ? true : false)
              _this.closeModel()
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
</style>


